* {
  box-sizing: border-box;
}

.dflex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu_container a {
  color: var(--tertiary-color);
  font-family: chiaroscura-semibold;
  text-decoration: none;
  height: 80px;
  font-size: 3.2vw;
}

@media (max-width: 768px) {
  .menu_container a {
    font-size: 30px;
  }
}

.menu_container a:hover {
  color: #eb851e;
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  -o-transition: 0.2s linear;
}

.nav-bar-wrapper {
  width: 100%;
  padding: 30px;
}

.list-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.bottom-copy-right-section-wrapper {
  width: 100%;
  padding: 30px;
}

.active {
  color: #eb851e;
}
