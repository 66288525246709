.legal-main-div{
    padding: 50px;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
}

.legal-title-text{
    font-family: chiaroscura-trial-light;
    font-size: 55px;
}

.legal-main-text{
     font-family: publica-light;
}

.legal-mini-bar{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;
}

.legal-mini-bar-buttons {
    font-family: publica-bold;
    background-color: transparent;
    border: 0;
    padding: 10px 18px;
    letter-spacing: 1.5px;
    font-size: 18px;
    cursor: pointer;
    color: var(--secondary-color);
}

.legal-mini-bar-buttons:hover{
    border: 1px var(--secondary-color) solid;
    color: var(--primary-color);
    transition: 0.2s linear;
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
    -o-transition: 0.2s linear;
}

.legal-mini-bar-buttons-selected{
    font-family: publica-bold;
    background-color: transparent;
    padding: 10px 18px;
    letter-spacing: 1.5px;
    font-size: 18px;
    cursor: pointer;
    border: 1px var(--secondary-color) solid;
    color: var(--primary-color);
    transition: 0.2s linear;
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
    -o-transition: 0.2s linear;
}

.legal-main-text-container{
    margin-top: 30px;
    text-align: justify;
    white-space: pre-line;
}

.legal-home-navigator-div{
    margin-top: 40px;
}

.legal-home-navigator-button{
    font-family: publica-bold;
    background-color: transparent;
    padding: 10px 18px;
    letter-spacing: 1.5px;
    font-size: 18px;
    cursor: pointer;
    border: 2px var(--secondary-color) solid;
    color: var(--primary-color);
    transition: 0.2s linear;
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
    -o-transition: 0.2s linear;
}

.legal-home-navigator-button:hover{
    background-color: var(--primary-color);
    color: white;
    transition: 0.2s linear;
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
    -o-transition: 0.2s linear;
}

@media screen and (max-width: 550px) {
    .legal-main-div{
        padding: 20px;
    }
}

@media screen and (max-width: 480px) {
    .legal-mini-bar{
        justify-content: center;
    }
}

@media screen and (max-width: 350px) {
    .legal-main-text-container{
        text-align: left;
    }
}