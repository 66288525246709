@font-face {
  font-family: publica-bold;
  src: url(../../public/fonts/PublicaSans/PublicaSans-Bold.otf);
}
@font-face {
  font-family: publica-light;
  src: url(../../public/fonts/PublicaSans/PublicaSans-Light.otf);
}
@font-face {
  font-family: publica-medium;
  src: url(../../public/fonts/PublicaSans/PublicaSans-Medium.otf);
}
@font-face {
  font-family: chiaroscura-trial-semi-bold;
  src: url(../../public/fonts/ChiaroscuraTrial/ChiaroscuraTRIAL-SmBd.otf);
}
@font-face {
  font-family: chiaroscura-trial-regular;
  src: url(../../public/fonts/ChiaroscuraTrial/ChiaroscuraTRIAL-Rg.otf);
}
@font-face {
  font-family: chiaroscura-trial-light;
  src: url(../../public/fonts/ChiaroscuraTrial/ChiaroscuraTRIAL-Lt.otf);
}
@font-face {
  font-family: chiaroscura-trial-medium;
  src: url(../../public/fonts/ChiaroscuraTrial/ChiaroscuraTRIAL-Md.otf);
}

:root {
  --primary-color: #512612; 
  --secondary-color: #202020;
  --tertiary-color: #ffe8d0;
}

body {
  font-family:  publica-bold, publica-light, publica-medium, sans-serif;
  background-color: #ffe8d0;
  padding-bottom: 0;
  margin: 0;
}
