.products-container{
    display: flex;
    justify-content: space-evenly;
    padding: 60px;
    color: white;
}

.products-body {
  background-image: url(../../assets/products_bg.png); /* Adjust the path to your image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; 
}

.products-subcontainer{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 15px;

}

.products-title{
    font-family: chiaroscura-trial-regular;
}


.products-img{
    margin-top: 30px;
    border-radius: 50%;
    width: 350px;
    height: 350px;
}

.products-info{
    width: 100%;
    font-family: publica-medium;
    text-align: justify;
    text-justify: inter-word;

}

.products-subtitle{
    font-family: chiaroscura-trial-regular;
    
}

.products-title-breaker{
    border-top: 5px dotted white;
    width: 10%;
    margin-left: 10px;
}

.products-read-more-btn {
    font-family: publica-medium;
    background-color: transparent;
    border: 1px white solid;
    letter-spacing: 1.5px;
    font-size: 18px;
    cursor: pointer;
    color: white;
    margin-top: 50px;
    width: 25%;
    height: 40px;
    
}
.products-read-more-btn:hover{
  background-color: var(--tertiary-color);
  color: var(--primary-color);
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  -o-transition: 0.2s linear;
}


@media (max-width: 1024px) {
    .products-container {
      flex-direction: column; /* Stack elements vertically on smaller screens */
      padding: 20px;
      align-items: center; /* Adjust padding for smaller screens */
    }
  
    .products-subcontainer {
      width: 90%; /* Increase width for better readability on smaller screens */
      margin-bottom: 20px; /* Adjust margin for smaller screens */
      align-items: center;
    }
  
    .products-img {
      width: 75%; /* Make the images responsive to the container width */
      height: auto; /* Maintain aspect ratio */
      margin-top: 20px; /* Adjust margin for smaller screens */
    }
  
    .products-title-breaker {
      width: 100%;
    }
  
    .products-title {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  
    .products-title-breaker {
      width: 100%; /* Adjust the underline width for smaller screens */
    }
  
    .products-subtitle {
      font-size: 16px; /* Adjust font size for smaller screens (increase) */
    }
  
    .products-info {
      font-size: 16px; /* Adjust font size for smaller screens (increase) */
      width: 100%;
    }
  
    .products-read-more-btn {
      font-size: 16px; /* Adjust font size for smaller screens */
      margin-top: 30px; /* Adjust margin for smaller screens */
      width: 75%;
    }
  }
  
