.products-info-title {
    text-align: center;
    padding: 20px; /* Adjust as needed */
  }
  
  .products-info-subtitles {
    padding-left: 10% /* Adjust as needed */
  }
  
  .products-info-list {
    list-style-type: circle;
    padding-left: 25%; /* Adjust as needed */
    margin-bottom: 50px; /* Remove default margin */
    font-family: chiaroscura-trial-regular;
  }
  
  /* Add margin to the list items for some space between them */
  .products-info-list li {
    margin-bottom: 5px; /* Adjust as needed */
  }
  