.main-footer-div{
    background-color: var(--primary-color);
    width: 100%;
    position: relative;
    bottom: 0;
    padding-top: 25px;
}

.main-footer-div-title{
    width: 100%;
    text-align: center;
}

.main-footer-div-title img{
    width: 200px;
}

.main-footer-div-information{

    color: var(--tertiary-color);
    font-family: publica-light;
    text-align: center;
    padding-left: 35px;
    padding-right: 35px;
}

.main-footer-div-main-section{
    display: flex;
    justify-content: space-around;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    flex-wrap: wrap;
} 

.main-footer-div-main-section div{
   text-align: center;
}
.main-footer-div-main-section span{
    display: block;
    text-align: center;
    font-family: publica-light;
    color: var(--tertiary-color);
    padding-bottom: 9px;
}

.main-footer-div-main-section .sub-title{
    font-size: 1.2em;
    color: var(--tertiary-color);
    font-family: publica-bold;

}

.mail-title{
    display: inline !important;
    font-family: publica-medium !important;
    padding-bottom: 22px !important;
}

.main-footer-div-main-section a{
    color: var(--tertiary-color);
    text-decoration: none;
    font-family: publica-light;
    padding-top: 22px !important;
}

.social-media-logo img{
    width: 50px;
}
.main-footer-div-main-section a:hover{
    color: #b95f06;
}

.developed-by-container {
  background-color: var(--tertiary-color);
  text-align: center;
  padding: 0.6rem;
  font-weight: 400;
  font-size: 15px;
  font-family: publica-light;
  color: var(--primary-color);
  margin: 0 !important;
}