.bottom-copyright-main-div{
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10px;
    padding-left: 25px;
}
.caravan-2023-word{
    font-family: chiaroscura-trial-light;
    font-size: 16px;
    color: var(--primary-color);
}
.legal-word{
    font-family: publica-light;
    font-size: 18px;
    color: var(--primary-color);
    cursor: pointer;
}
.legal-word:hover{
    transition: 0.2s linear;
    font-size: 20px;
}
.legal-word-about:hover{
    transition: 0.2s linear;
    font-size: 20px;
}
.caravan-2023-word-light{
    font-family: chiaroscura-trial-light;
    font-size: 16px;
    color: var(--tertiary-color);
}
.legal-word-light{
    font-family: publica-light;
    font-size: 18px;
    color: var(--tertiary-color);
}
.caravan-2023-word-about{
    font-family: chiaroscura-trial-light;
    color: var(--tertiary-color);
}
.legal-word-about{
    font-family: publica-light;
    color: var(--tertiary-color);
    cursor: pointer;

}