.navigation-bar-main-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 25px;
}
.navigation-bar-main-div-logo{
    width: 14%;
    min-width: 145px;
    cursor: pointer;
}
.navigation-bar-right-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navigation-bar-right-div-drawer{
    width: 45px;
    height: 45px;
    cursor: pointer;
}

.navigation-bar-right-div-drawer-light{
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 25px;
    margin-left: 8px;
}

.navigation-bar-right-div-exit{
    width: 45px;
    height: 45px;
    cursor: pointer;
}
.navigation-bar-right-div-drawer:hover{
    transition: 0.2s linear;
    transform: translate(0,-3%);
    -webkit-transform: translate(0,-3%);
    -moz-transform: translate(0,-3%);
    -ms-transform: translate(0,-3%);
    -o-transform: translate(0,-3%);
}

.navigation-bar-right-div-span-inactive{
    margin-right: 15px;
    color: var(--primary-color);
    font-family: publica-medium;
    font-size: 22px;
    cursor: pointer;
}

.navigation-bar-right-div-span-active{
    color: var(--primary-color);
    margin-right: 15px;
    font-family: publica-medium;
    font-size: 22px;
    cursor: pointer;
    border-bottom: 1.5px solid var(--primary-color);
}

.navigation-bar-right-div-span-active:hover{
    transition: 0.2s linear;
    transform: translate(0,-3%);
    -webkit-transform: translate(0,-3%);
    -moz-transform: translate(0,-3%);
    -ms-transform: translate(0,-3%);
    -o-transform: translate(0,-3%);
}

@media screen and (max-width: 490px) {
    .navigation-bar-right-div-drawer{
        width: 40px;
        height: 40px;
    }
    .navigation-bar-right-div-span-active{
        display: none;
    }
    .navigation-bar-right-div-span-inactive{
        display: none;
    }
}