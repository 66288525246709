.home-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.home-main-div-img {
  width: 450px;
}
.home-sub-div {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.home-sub-div span {
  font-size: 45px;
  font-family: chiaoroscura-trial-medium;
  color: var(--primary-color);
  text-wrap: nowrap !important;
}

.read-more-btn {
  font-family: publica-medium;
  background-color: transparent;
  border: 1px white solid;
  padding: 10px 18px;
  letter-spacing: 1.5px;
  font-size: 18px;
  cursor: pointer;
  color: var(--tertiary-color);
  position: absolute;
  top: 300%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.read-more-btn:hover {
  background-color: var(--tertiary-color);
  color: var(--primary-color);
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  -o-transition: 0.2s linear;
}

@media screen and (max-width: 490px) {
  .home-main-div-img {
    width: 350px;
  }
  .home-sub-div span {
    font-size: 35px;
  }
}

@media screen and (max-width: 400px) {
  .home-sub-div span {
    font-size: 36px !important;
    text-wrap: nowrap !important;
  }
  .home-sub-div {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -10%);
    -webkit-transform: translate(-50%, -10%);
    -moz-transform: translate(-50%, -10%);
    -ms-transform: translate(-50%, -10%);
    -o-transform: translate(-50%, -10%);
  }
  .home-sub-div button {
    font-family: publica-medium;
    background-color: transparent;
    border: 1px white solid;
    padding: 8px 15px;
    letter-spacing: 1.5px;
    font-size: 15px;
    transform: translate(-50%, -10%);
    -webkit-transform: translate(-50%, -10%);
    -moz-transform: translate(-50%, -10%);
    -ms-transform: translate(-50%, -10%);
    -o-transform: translate(-50%, -10%);
  }
}

@media screen and (max-width: 365px) {
  .home-main-div-img {
    width: 250px !important;
  }
  .home-sub-div span {
    font-size: 25px !important;
  }
  .home-sub-div {
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -10%);
    -webkit-transform: translate(-50%, -10%);
    -moz-transform: translate(-50%, -10%);
    -ms-transform: translate(-50%, -10%);
    -o-transform: translate(-50%, -10%);
  }
  .home-sub-div button {
    font-family: publica-medium;
    background-color: transparent;
    border: 1px white solid;
    padding: 8px 15px;
    letter-spacing: 1.5px;
    font-size: 11px;
    transform: translate(-50%, -10%);
    -webkit-transform: translate(-50%, -10%);
    -moz-transform: translate(-50%, -10%);
    -ms-transform: translate(-50%, -10%);
    -o-transform: translate(-50%, -10%);
  }
}
