
.about-us-body{
    font-family: chiaroscura-trial-semi-bold;
    width:100%; 
    height: 100%; 
    background-color: var(--secondary-color);
    color: var(--tertiary-color);

}

.about-main-div{
    padding: 50px;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    color: var(--tertiary-color);
   
}

.about-us-title{
    font-family: chiaroscura-trial-semi-bold;
    font-size: 25px;


}
.about-us-special-text-color{
    color: var(--tertiary-color);

}

.about-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    
  }
  
  .about-section,
  .values-section,
  .provides-section {
    font-family: publica-light;
    font-size: 14px;
    margin-left: 350px;
    margin-right: 350px;
    padding: 20px;
    
  }

  .about-us-paragraphs{
    color: white; 
    font-size: 18px;
    line-height: 1.75;
    text-align: left;
    text-align: justify;
    text-justify: inter-word;
  }

  .about-us-title-underline{
    
    width: 10%;
    height: 8px;
    background-color: var(--tertiary-color);
  }
  
  @media (max-width: 768px) {
    .about-section,
    .values-section,
    .provides-section { 
      margin-left: 7px; /* Adjust margins for smaller screens */
      margin-right: 7px;
    }
    
    .about-us-paragraphs {
      font-size: 14px; /* Adjust font size for smaller screens */
    }
    
    .about-us-title-underline {
      width: 15%; /* Adjust the underline width for smaller screens */
    }
  }

  
  
  